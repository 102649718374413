<template>
  <li
    ref="listItem"
    :class="[
      'accordion__item',
      visible && !hideShadows && 'accordion__item--active',
      mobileNavigationClass,
      {
        'accordion__item--simple': isSimple,
      },
    ]"
  >
    <button
      type="button"
      class="accordion__header"
      :class="{ 'accordion__header--active': visible }"
      @click="open"
    >
      <!-- This slot will handle the title/header of the accordion and is the part you click on -->
      <slot name="accordion-header-icon" />
      <slot v-if="!accordionHeader" name="accordion-header" />
      <span class="heading--5 accordion__header-text">
        <span v-if="isListNumbered" class="accordion__header-number">{{
          listNumber
        }}</span>
        {{ accordionHeader }}
      </span>

      <img
        class="accordion__icon"
        :class="{ 'accordion__icon--active': visible }"
        width="32"
        height="32"
        src="@/assets/icons/16-chevron-down-navy-800.svg?url"
        alt="expand icon"
      />
    </button>

    <transition
      name="accordion"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @before-leave="onEnter"
      @after-leave="onAfterLeave"
    >
      <div
        v-show="visible"
        class="accordion__content"
        :content="{ 'accordion__content--numbered': isListNumbered }"
      >
        <!-- This slot will handle all the content that is passed to the accordion -->
        <slot name="accordion-content" />
        <span class="space" />
      </div>
    </transition>
  </li>
</template>

<script lang="ts" setup>
interface Props {
  accordionHeader?: string
  isListNumbered?: boolean
  hideShadows?: boolean
  isInMobileNavbar?: boolean
  isSimple?: boolean
  openOnScroll?: boolean
}

interface Accordion {
  count: number
  active: null | number
}

const listItem = ref<HTMLElement | null>(null)
const sectionId = ref('')

const userAccordion = inject('accordionElement') as Accordion
const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'opened', value: number): void }>()

const index = ref(0)

const visible = computed(() => index.value === userAccordion.active)

const listNumber = computed(() => {
  return props.isListNumbered ? `${index.value + 1}.` : ''
})

const mobileNavigationClass = computed(
  () => props.isInMobileNavbar && 'accordion__item--mobile-nav',
)

const open = () => {
  if (visible.value) {
    userAccordion.active = null
  } else {
    userAccordion.active = index.value
  }
}

const onAfterEnter = (el: Element) => {
  emit('opened', el.scrollHeight)
}

const onEnter = (el: Element) => {
  ;(el as HTMLElement).style.height = el.scrollHeight + 'px'
}

const onAfterLeave = (el: Element) => {
  ;(el as HTMLElement).style.height = ''
}

const openOnHashChange = () => {
  if (!sectionId.value) {
    return
  }
  if (window.location.hash === `#${sectionId.value}`) {
    open()
  }
}

onMounted(() => {
  index.value = userAccordion.count++

  if (listItem.value && props.openOnScroll) {
    const closestSection = listItem.value.closest('section')

    if (closestSection) {
      sectionId.value = closestSection.id
      openOnHashChange()
      window.addEventListener('hashchange', openOnHashChange)
    }
  }
})

onUnmounted(() => {
  window.removeEventListener('hashchange', openOnHashChange)
})
</script>

<style lang="scss" scoped>
$accordion-border: 1px solid var(--gray-300);

.accordion {
  $root: &;
  &__item {

    cursor: pointer;
    position: relative;
    color: var(--cobalt-800);
    border-top: $accordion-border;
    transition: 0.02s ease-in-out background-color;

    &:last-of-type {
      border-bottom: $accordion-border;
    }

    &--active {
      @include accordion-card-shadow;
      
      background-color: var(--white) !important;
      border-radius: 8px;
      border-top: 0;
      border: 0;
      padding-top: 3px;
      margin-top: -3px;
      margin-bottom: 20px;
      padding-left: 5px;
      margin-left: -5px;
      padding-right: 5px;
      margin-right: -5px;

      &:last-of-type {
        border-bottom: 0;
      }

    }
    &--simple {
      box-shadow: none;
      border-top: none;
      #{$root}__header {
        padding: 0;
        min-height: 50px;
      }
      #{$root}__header-text {
        @include heading-subheading;
        padding: 0 30px 24px 0;
      }
      #{$root}__content {
        padding-left: 0;
      }
      #{$root}__icon {
        align-self: flex-start;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 80px;
    padding: 0 20px;
    width: 100%;
    flex-wrap: nowrap;
    flex: 0;

    @media (min-width: $breakpoint-md) {
      padding: 0 40px;
    }
  }

  &__header-text {
    flex-wrap: nowrap;
    display: flex;
    flex: 1;
    text-align: left;
    margin: 0;
    padding: 28px 30px 28px 0;
  }

  &__header-number {
    padding-right: 14px;
  }

  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: auto;
    width: 32px;
    padding: 8px;
    will-change: auto;
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &--active {
      transform: rotate(-180deg);
    }
  }

  &__content {
    padding: 0 26px 0 20px;

    &--numbered {
      padding: 0 26px 0 46px;
      word-break: keep-all;
    }

    @media (min-width: $breakpoint-md) {
      padding: 0 56px 0 40px;

      &--numbered {
        padding: 0 56px 0 68px;
      }
    }
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition:
    height 0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
    opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}

.space {
  display: block;
  visibility: hidden;
  height: 40px;
  width: 100%;
}

.accordion .accordion__item--mobile-nav {
  border: none;
  border-bottom: 1px solid var(--gray-100);

  &:last-of-type {
    border: none;
    border-bottom: 1px solid var(--gray-100);
  }

  .accordion__header {
    min-height: 0;
    padding: 0 32px;
    height: 80px;
  }

  .accordion__header-text {
    padding: 0;
    height: 100%;
    align-items: center;
  }

  .accordion__space {
    display: none;
  }

  .accordion__content {
    padding-inline: 0;
  }

  .accordion__icon {
    height: 16px;
    width: 16px;
    padding: 0;
  }

  .space {
    display: none;
  }
}
</style>
